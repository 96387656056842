.wizard.wizard-5 .wizard-aside {
  border-top-left-radius: 0.42rem;
  border-bottom-left-radius: 0.42rem; }
  .wizard.wizard-5 .wizard-aside .wizard-nav {
    padding: 0; }
    .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step {
        padding: 0.75rem 0;
        -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
        transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
        transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
        transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
        margin-bottom: 1.5rem; }
        .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step:last-child {
          margin-bottom: 0; }
        .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step .wizard-wrapper {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex; }
        .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step .wizard-icon {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
          transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
          transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
          transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
          min-width: 50px;
          height: 50px;
          border-radius: 50px;
          background-color: #F3F6F9;
          margin-right: 1.4rem; }
          .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step .wizard-icon .wizard-check {
            display: none;
            font-size: 1.4rem; }
          .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step .wizard-icon .wizard-number {
            font-weight: 600;
            color: #3F4254;
            font-size: 1.35rem; }
        .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step .wizard-label {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center; }
          .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title {
            color: #181C32;
            font-weight: 500;
            font-size: 1.4rem; }
          .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-desc {
            color: #B5B5C3;
            font-size: 1.08rem;
            font-weight: 500; }
        .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] {
          -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
          transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
          transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
          transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
          .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-icon {
            -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
            transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
            transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
            transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
            background-color: #C9F7F5; }
            .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-icon .wizard-check {
              color: #1BC5BD;
              display: none; }
            .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-icon .wizard-number {
              color: #1BC5BD; }
          .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-label .wizard-title {
            color: #181C32; }
          .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-label .wizard-desc {
            color: #B5B5C3; }
        .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"]:last-child .wizard-icon, .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state="done"] .wizard-icon {
          -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
          transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
          transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
          transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
          background-color: #C9F7F5; }
          .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"]:last-child .wizard-icon .wizard-check, .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state="done"] .wizard-icon .wizard-check {
            color: #1BC5BD;
            display: inline-block; }
          .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"]:last-child .wizard-icon .wizard-number, .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state="done"] .wizard-icon .wizard-number {
            display: none; }
        .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"]:last-child .wizard-label .wizard-title, .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state="done"] .wizard-label .wizard-title {
          color: #B5B5C3; }
        .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"]:last-child .wizard-label .wizard-desc, .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state="done"] .wizard-label .wizard-desc {
          color: #D1D3E0; }

.wizard.wizard-5 .wizard-content {
  background-color: #F3F6F9;
  border-top-right-radius: 0.42rem;
  border-bottom-right-radius: 0.42rem; }
  .wizard.wizard-5 .wizard-content .form-group .fv-help-block {
    font-size: 1.1rem !important;
    padding-top: 3px; }

@media (max-width: 991.98px) {
  .wizard.wizard-5 .wizard-aside {
    border-top-left-radius: 0.42rem;
    border-top-right-radius: 0.42rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
    .wizard.wizard-5 .wizard-aside .wizard-nav {
      padding: 0;
      -ms-flex-line-pack: center;
      align-content: center; }
      .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step {
        margin-bottom: .5rem; }
        .wizard.wizard-5 .wizard-aside .wizard-nav .wizard-steps .wizard-step:last-child {
          margin-bottom: 0; }
  .wizard.wizard-5 .wizard-content {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0.42rem;
    border-bottom-right-radius: 0.42rem; } }
